import { REHYDRATE } from "redux-persist";
import { actionRehydrate } from "@utils";
import {
  TREE_FETCH,
  TREE_FETCH_PENDING,
  COWORKING_TREE_FETCH
} from "../../constants";

const initialState = {
  data: {
    results: [],
  },
  tree: [],
  coworking_tree: [],
  isFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return actionRehydrate(state, action.payload, "structures");
    case TREE_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case TREE_FETCH:
      return {
        ...state,
        isFetching: false,
        tree: action.payload.data.data.results,
      };
    case COWORKING_TREE_FETCH:
      return {
        ...state,
        isFetching: false,
        coworking_tree: action.payload.data.data.results
      }
    default:
      return state;
  }
}
