import {
  ENTITIES_TIMES_FETCH,
  ENTITIES_TIMES_FETCH_PENDING
} from '../../constants';

const initialState = {
  isFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENTITIES_TIMES_FETCH_PENDING:
      return {
        isFetching: true,
      };
    case ENTITIES_TIMES_FETCH:
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}
