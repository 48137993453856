import { ACTIONS_FORM_ADD,ACTIONS_FORM_REMOVE } from '../../constants';
import { REHYDRATE } from 'redux-persist';
const initialState = [];

export default function actionsFormReducers(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      return action.payload ? action.payload.actionsForm : state;
    case ACTIONS_FORM_REMOVE:
      return state.filter( value => value.id !== action.payload)
    case ACTIONS_FORM_ADD :
        return [...state,action.payload];
    default:
      return state;
  }
}