import {
  SESSION_SET_INITAL_WORK,
  SESSION_SET_LAST,
  PING_PING,
  SESSION_START_MONITORING,
  SET_ENROLL,
  SET_CAMERA_DEVICE,
} from '../../constants';

const initialState = {
  initialWork: null,
  session: null,
  service: null,
  cameraDevice: { value: '', label: '' },
  enrolled: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SESSION_START_MONITORING:
      return {
        ...state,
        service: {
          ...state.service,
          monitoringStart: true,
        },
      };
    case SESSION_SET_LAST:
      return {
        ...state,
        session: action.payload,
      };
    case PING_PING:
      return {
        ...state,
        service: action.payload,
      };
    case SESSION_SET_INITAL_WORK:
      return {
        ...state,
        initialWork: action.payload,
      };
    case SET_CAMERA_DEVICE: {
      return { ...state, cameraDevice: action.payload };
    }
    case SET_ENROLL: {
      return { ...state, enrolled: action.payload };
    }
    default:
      return state;
  }
};
