import {
  SCHEDULES_FETCH_PENDING,
  SCHEDULE_REJECTED,
  SCHEDULES_FETCH,
  SCHEDULES_FETCH_MORE,
} from "../../constants";
import { REHYDRATE } from "redux-persist";
import { actionRehydrate } from "@utils";

const initialState = {
  data: {
    results: [],
  },
  isFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return actionRehydrate(state, action.payload, "schedules");
    case SCHEDULES_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SCHEDULE_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    case SCHEDULES_FETCH:
      return {
        data: action.payload.data.data,
        isFetching: false,
      };
    case SCHEDULES_FETCH_MORE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload.data.data,
          results : _.merge(action.payload.data.data.results,state.data.results)
        },
      };
    default:
      return state;
  }
}
