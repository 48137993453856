
import React from 'react'
import UILoader from '@components/ui-loader'
import Spinner from '@components/spinner/Loading-spinner'

import { CardText } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const IntelliLoading = ({children}) => {
  const { t } = useTranslation();
  const loading = useSelector(({ process }) => process.mode);

  const Loader = () => {
    return (
      <>
        <Spinner />
        <CardText className='mb-0 mt-1 text-white'>{t("General.PleaseWait")}</CardText>
      </>
    )
  }

  return (
    <UILoader blocking={loading} loader={<Loader />} className="full">
      {children}
    </UILoader>
  )
}

export default IntelliLoading;