import {
  LOGIN,
  LOGOUT,
  SELECT_ENTITY,
  SET_TEMPLATE_FACE,
  MODIFY_USER_SETTING
} from '../../constants';
import { actionRehydrate } from "@utils";
import useJwt from "@src/auth/jwt/useJwt";
import { REHYDRATE } from "redux-persist";

const config = useJwt.jwtConfig;

const initialState = {
  id_client: null,
  id_instance: null,
  person: {},
  selectedEntity: null,
  token: null,
  uid_client: null,
  user: [],
  userData: {},
  version: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return actionRehydrate(state, action.payload, "auth");
    case LOGIN:
      return {
        ...state,
        ...action.payload,
        // [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        // [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      };
    case SELECT_ENTITY:
      return {
        ...state,
        selectedEntity: action.payload,
      };
    case SET_TEMPLATE_FACE:
      return {
        ...state,
        person: { ...state.person, template_face: action.payload },
      };
    case MODIFY_USER_SETTING:
      return {
        ...action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("uidClient");
      localStorage.removeItem("uidEntity");
      localStorage.removeItem("persist:root");
      localStorage.removeItem("idEntity");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
      const obj = { ...action };
      delete obj.type;
      return { userData: {}, ...obj };
    default:
      return state;
  }
};

export default authReducer;
