import { POLAR_GET_DATA } from "../../constants";

const initialState = {
  polarData: {
      series: [],
      labels: [],
      date: [],
      productive: [],
      afk: [],
  },
};

const graphicReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLAR_GET_DATA:
      return {
        ...state,
        polarData: action.payload
      };
    default:
      return state;
  }
};

export default graphicReducer;
