import {
  ENTITIES_PERMISSION_FETCH,
  ENTITIES_PERMISSION_FETCH_PENDING,
  ENTITIES_PERMISSION_REJECTED,
} from '../../constants';

const initialState = {
  isFetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENTITIES_PERMISSION_FETCH_PENDING:
      return {
        isFetching: true,
      };
    case ENTITIES_PERMISSION_REJECTED:
      return {
        isFetching: false,
      };
    case ENTITIES_PERMISSION_FETCH:
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}
