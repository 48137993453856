import React, { Children, useRef } from 'react';
import { UncontrolledTooltip, Tooltip } from 'reactstrap';

const IntelliTooltip = ({placement = 'bottom', title = "", children, tooltipId}) => {
  const ref = useRef(null);

  let id = ref.current !== null ? ref : `tooltip-${Math.random().toString(36).substring(2, 18).replace(/[0-9]/g, '')}`;

  if(tooltipId) {
    id = `tooltip-${tooltipId}`;
  }

  if(!children) return null;
  return (
    <>
      {React.cloneElement(children, { ref: ref, id: id })}
      {title !== "" && (
        <UncontrolledTooltip 
          placement={placement}
          target={id}
          style={{ textAlign: 'left'}}
          modifiers={{preventOverflow: {boundariesElement: "viewport"}}}
        >
          {Children.toArray(String(title).split('\n').map(item => <> {item}<br /> </>))}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default IntelliTooltip;