import { REHYDRATE } from "redux-persist/es/constants";
import { actionRehydrate } from "@utils";
import {
  TIMELOGS_FETCH_PENDING,
  TIMELOGS_FETCH,
  TIMELOGS_REJECTED
} from '../../constants';

const initialState = {
  data: {
    results: [],
  },
  isFetching: false,
};

export default (state = initialState, action) => {

  switch (action.type) {
    case REHYDRATE:
      return actionRehydrate(state, action.payload, "timelogs");
    case TIMELOGS_FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case TIMELOGS_FETCH:
      return {
        data: { ...action.payload.data },
        isFetching: false
      };
    case TIMELOGS_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
