// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import actionsFormReducers from "./common/actionsFormReducer";
import processReducer from "./common/process";
import navbar from "./navbar";
import layout from "./layout";
import session from "./productivity/session";
import graphics from "./graphics";
import verificationModesReducer from "./common/verificationModesReducer";
import entitiesPermissionsFetch from "./permissions/entitiesPermissionsFetch";
import timesFetch from "./times/timesFetch";
import timelogsFetch from "./common/timelogsFetch";
import timeZonesReducer from "./common/timeZonesReducer";
import structuresReducer from "./common/structuresReducer";
import structuresTypesReducer from "./common/structuresTypesReducer";
import schedulesReducer from "./scheduling/schedulesReducer";
import statusReducer from "./common/statusReducer";
import realTimeTimezoneReducer from "./common/realTimeTimezoneReducer";
import realTimeDiference from "./common/realTimeDiference";

const appReducer = combineReducers({
  auth,
  actionsForm: actionsFormReducers,
  entitiesPermissions: entitiesPermissionsFetch,
  graphics,
  layout,
  navbar,
  process: processReducer,
  schedules: schedulesReducer,
  status: statusReducer,
  structures: structuresReducer,
  structuresTypes: structuresTypesReducer,
  session,
  times: timesFetch,
  timelogs: timelogsFetch,
  timeZones: timeZonesReducer,
  verificationModes: verificationModesReducer,
  realTimeByTimezone: realTimeTimezoneReducer,
  realTimeDiference: realTimeDiference
});


const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
