// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/assets/fonts/myintelli/style.css";
import "./@core/assets/fonts/fontawesome/css/all.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

import { LoadScript } from "@react-google-maps/api";
import useJwt from "@src/auth/jwt/useJwt";
import IntelliLoading from "./component/common/IntelliLoading";

const config = useJwt.jwtConfig;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <IntelliLoading>
              <LoadScript
                googleMapsApiKey={config.keyGoogle}
                language={i18n.language}
                loadingElement={<></>}
              >
                <LazyApp />
                <ToastContainer position="bottom-right" newestOnTop/>
              </LoadScript>
            </IntelliLoading>
          </ThemeContext>
        </Suspense>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
