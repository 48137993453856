
import axios from "axios";
import useJwt from "@src/auth/jwt/useJwt";
import {
  PROCESSING,
  PROCESS_COMPLETE
} from "../../constants";
import { getHeaders } from "@utils"
import jwtDefaultConfig from "../../../@core/auth/jwt/jwtDefaultConfig";
import _ from "lodash";

const config = useJwt.jwtConfig;


export const errorBoundaryInsert = (data) => (dispatch) => {
  if(!jwtDefaultConfig.insertError) {
    return false;
  }
  dispatch({ type: PROCESSING });
  return axios
    .post(`${config.urlApi}/system_errors_clients`, data, {
      headers: getHeaders(),
    })
    .then(() => {
      dispatch({ type: PROCESS_COMPLETE });
      return true;
    })
    .catch((error) => {
      dispatch({ type: PROCESS_COMPLETE });
      console.log(error);
      return false;
    });
};