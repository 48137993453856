import { useState, useLayoutEffect } from "react";
import i18next from "i18next";
import moment from "moment-timezone";
import { Badge } from "reactstrap";
import { LOGOUT } from "../redux/constants";
import useJwt from "@src/auth/jwt/useJwt";
import {
  faFingerprint,
  faIdCard,
  faKeyboard,
  faEye,
  faCar,
  faUserTie,
  faDesktop,
  faMobile,
  faRightToBracket,
  faRightFromBracket,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntelliTooltip from "../component/common/IntelliTooltip";
import defaultAvatar from "@src/assets/images/portrait/small/person.png";
import IntelliToast from "../component/common/IntelliToast";
import { errorBoundaryInsert } from "../redux/actions/error";

const config = useJwt.jwtConfig;

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
};

export const capitalize = (val) => {
  if (typeof val !== "string") return val;
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
};

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!localStorage.getItem("uidClient");

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#2385F4", // for selected option bg-color
    neutral10: "#2385F4", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const isValidatedEmail = (email) => {
  if (!email) {
    return false;
  }
  if (
    !!email &&
    email.length > 0 &&
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
      email
    )
  ) {
    return true;
  }
  return false;
};

export const formatsDates = [
  { id: "YYYY-MM-DD", value: "2021-05-20", mask: "yyyy-MM-dd" },
  { id: "DD-MM-YYYY", value: "20-05-2021", mask: "dd-MM-yyyy" },
  { id: "MM-DD-YYYY", value: "05-20-2021 ", mask: "MM-dd-yyyy" },
  { id: "YYYY/MM/DD", value: "2021/05/20", mask: "yyyy/MM/dd" },
  { id: "DD/MM/YYYY", value: "20/05/2021", mask: "dd/MM/yyyy" },
  { id: "MM/DD/YYYY", value: "05/20/2021 ", mask: "MM/dd/yyyy" },
];

export const daysOfWeek = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

export const formatTimes = [
  { id: "HH:mm", value: "24 H" },
  { id: "hh:mm a", value: "12 H" },
];

export const formatHours = [
  { value: 1, label: "00 hrs 30 min" },
  //{ value: 2, label: "0.5 hrs" },
  //{ value: 3, label: "00:30 hrs" },
];

export const languajes = [
  { value: "es-CO", label: "Español CO" },
  { value: "es-DO", label: "Español DO" },
  { value: "es-VE", label: "Español VE" },
  { value: "en-US", label: "English US" },
];

export const colorsApexCharts = [
  "#ffc94c",
  "#f48684",
  "#93cf96",
  "#45b6f3",
  "#775dd0",
  "#747575",
  "#FA729C",
  "#8d6e63",
  "#34b0a0",
  "#ab47bc",
];

export const getCutItems = () => {
  return [
    { value: 0, label: i18next.t("General.Daily") },
    { value: 1, label: i18next.t("General.Weekly") },
    { value: 2, label: i18next.t("General.Biweekly") },
    { value: 3, label: i18next.t("General.Monthly") },
  ];
};

export const statusApprovalItems = () => {
  return [
    { value: 1, label: i18next.t("Permissions.level1") },
    { value: 2, label: i18next.t("Permissions.level2") },
    { value: 3, label: i18next.t("Permissions.level3") },
    { value: 4, label: i18next.t("Permissions.level4") },
    { value: 5, label: i18next.t("Permissions.level5") },
    { value: 6, label: i18next.t("Permissions.level5") },
  ];
};

export const getTypeAccess = () => {
  return [
    { value: 0, label: "" /*i18next.t(`General.entryExit`)*/ },
    { value: 1, label: i18next.t(`General.Entry`) },
    { value: 2, label: i18next.t(`General.Exit`) },
  ];
};

export const typesModesIcons = [
  { type: "FINGERPRINT", icon: <FontAwesomeIcon icon={faFingerprint} /> },
  { type: "FINGERPRINT_PC", icon: <FontAwesomeIcon icon={faFingerprint} /> },
  { type: "FINGERPRINT_CARD", icon: <FontAwesomeIcon icon={faFingerprint} /> },
  {
    type: "FINGERPRINT_CARD_PIN",
    icon: <FontAwesomeIcon icon={faFingerprint} />,
  },
  { type: "CARD", icon: <FontAwesomeIcon icon={faIdCard} /> },
  { type: "PIN", icon: <FontAwesomeIcon icon={faKeyboard} /> },
  { type: "PIN_PC", icon: <FontAwesomeIcon icon={faKeyboard} /> },
  { type: "PIN_MOBILE", icon: <FontAwesomeIcon icon={faKeyboard} /> },
  { type: "FACIAL", icon: <i className="intelli_face" /> },
  { type: "FACIAL_MOBILE", icon: <i className="intelli_face" /> },
  { type: "FACIAL_PC", icon: <i className="intelli_face" /> },
  { type: "EYES", icon: <FontAwesomeIcon icon={faEye} /> },
  { type: "IRIS", icon: <FontAwesomeIcon icon={faEye} /> },
  { type: "PLATE", icon: <FontAwesomeIcon icon={faCar} /> },
  { type: "USER", icon: <FontAwesomeIcon icon={faUserTie} /> },
  { type: "PC", icon: <FontAwesomeIcon icon={faDesktop} /> },
  { type: "MOBILE", icon: <FontAwesomeIcon icon={faMobile} /> },
];

export const styleMaps = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#242f3e" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

export const getDeviceIcon = (verification_mode) => {
  if (
    [
      "FINGERPRINT",
      "CARD",
      "PIN",
      "FACIAL",
      "IRIS",
      "FINGERPRINT_CARD",
      "FINGERPRINT_CARD_PIN",
      "PLATE",
    ].includes(verification_mode)
  ) {
    return <i className="intelli_marcaje-verificado" />;
  }
  if (
    ["USER", "FACIAL_PC", "FINGERPRINT_PC", "PIN_PC", "PC"].includes(
      verification_mode
    )
  ) {
    return <FontAwesomeIcon icon={faDesktop} />;
  }
  if (["FACIAL_MOBILE", "PIN_MOBILE", "MOBILE"]) {
    return <FontAwesomeIcon icon={faMobile} />;
  }
  return <FontAwesomeIcon icon={faTimes} />;
};

export const getLangMoment = () => {
  let lang = "";
  if (i18next.language === undefined) {
    const userLang = navigator.language || navigator.userLanguage;
    lang = userLang.split("-")[0] === "es" ? "es-CO" : "en-US";
  } else {
    lang = i18next.language;
  }
  return lang.split("-")[0];
};

export const getHeaders = () => {
  const header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (!!localStorage.token) {
    const token = `Bearer ${localStorage.token}`;
    return { ...header, Authorization: token };
  }
  return header;
};

export const getTimezone = () => {
  return moment.tz.guess()
}

export const fixTime = (errorTime, time) => {
  return moment(time).add(errorTime * -1, "m").format("YYYY-MM-DD HH:mm:ss");
};

export const getDatetime = (date, timezone_pg) => {
  const dateUtc = moment.tz(date, "UTC");
  dateUtc.tz(timezone_pg);
  return dateUtc;
};

export const hoursDecimalToFractions = (time) => {
  let hours = Math.floor(time);
  let minutes = (time - hours) * 60;
  let seconds = (minutes - Math.floor(minutes)) * 60;
  minutes = Math.trunc(Math.ceil(Math.trunc(minutes * 10) / 10));
  if (minutes === 60) {
    hours = hours + 1;
    minutes = 0;
    seconds = 0;
  }
  return {
    hours,
    minutes,
    seconds: Math.floor(seconds),
  };
};

export const setFormatHours = (time_quantity) => {
  if (isNaN(time_quantity)) return null;
  const t = hoursDecimalToFractions(time_quantity);
  return `${t.hours.toString().padStart(2, "0")}:${t.minutes
    .toString()
    .padStart(2, "0")} h`;
};

export const setName = (data, user) => {
  const formatName =
    user?.id_format_name !== undefined ? user.id_format_name : 1;
  const { first_name, middle_name, last_name1, last_name2 } = data;
  const fName = first_name !== null ? capitalize(first_name) : "";
  const mName = middle_name !== null ? capitalize(middle_name) : "";
  const lName = last_name1 !== null ? capitalize(last_name1) : "";
  const lName2 = last_name2 !== null ? capitalize(last_name2) : "";
  let name = "";
  switch (formatName) {
    case 0: //FnLn1
      name = `${fName} ${lName}`;
      break;
    case 1: //FnLn1Ln2
      name = `${fName} ${lName} ${lName2}`;
      break;
    case 2: //FnMnLn1Ln2
      name = `${fName} ${mName} ${lName} ${lName2}`;
      break;
    case 3: //FnMnLn1L
      name = `${fName} ${mName} ${lName}`;
      break;
    case 4: //Ln1Fn
      name = `${lName} ${fName} `;
      break;
    case 5: //Ln1Ln2Fn
      name = `${lName} ${lName2} ${fName}`;
      break;
    case 6: //Ln1Ln2FnMn
      name = `${lName} ${lName2} ${fName} ${mName}`;
      break;
    case 7: //Ln1FnMn
      name = `${lName} ${fName} ${mName}`;
      break;
  }
  return name;
};

export const documentTypesItems = () => {
  return [
    { value: 1, label: i18next.t("DocumentType.1") },
    { value: 2, label: i18next.t("DocumentType.2") },
    { value: 3, label: i18next.t("DocumentType.3") },
    { value: 4, label: i18next.t("DocumentType.4") },
    { value: 5, label: i18next.t("DocumentType.5") },
    { value: 6, label: i18next.t("DocumentType.6") },
    { value: 7, label: i18next.t("DocumentType.7") },
  ];
};

export const formatDateTime = (
  format = null,
  date = null,
  type = "date",
  settings_user
) => {
  if (!settings_user) return "";
  const typeFormat = `format_${type}`;
  if (format === null) {
    format = settings_user[typeFormat];
  }
  if (type === "time") {
    const formatTime = settings_user.format_time;
    if (date === null) {
      return moment().format(format);
    } else {
      if (date.length === 8) {
        return moment(date, "HH:mm:ss").format(formatTime);
      }
      if (date.length === 5) {
        return moment(date, "HH:mm").format(formatTime);
      }
      return moment(date).format(format);
    }
  }
  return date === null ? moment().format(format) : moment(date).format(format);
};

export function setError400(obj) {
  const errMssgCode = Object.keys(obj)[0];
  let seted = false;
  switch (errMssgCode) {
    case "V401":
      IntelliToast.warning(i18next.t(`logicErrors.otherVistActive`));
      seted = true;
      break;
    case "VE401":
      obj.invalids.forEach((parking) => {
        IntelliToast.warning(
          i18next.t(`General.warning`),
          `${i18next.t(`Vehicle.parking`)} ${parking} ${i18next.t(
            `Vehicle.parkingNotAvailable`
          )}`
        );
      });
      seted = true;
      break;
    case "VS401":
      IntelliToast.warning(
        i18next.t(`logicErrors.otherVisitScheduledActivated`)
      );
      seted = true;
      break;
    case "U400":
      IntelliToast.warning(i18next.t(`logicErrors.youCantEraseYourself`));
      seted = true;
      break;
    case "U401":
      IntelliToast.warning(i18next.t(`logicErrors.youCantInactivateYourself`));
      seted = true;
      break;
    case "ML401":
      IntelliToast.warning(i18next.t(`logicErrors.structureHasNotParking`));
      seted = true;
      break;
    case "ML402":
      IntelliToast.warning(
        i18next.t(`logicErrors.structureFatherHasNotParking`)
      );
      seted = true;
      break;
    case "S401":
      IntelliToast.warning(
        i18next.t(`logicErrors.canNotInactivateDeviceAcInUse`)
      );
      seted = true;
      break;
    case "S402":
      IntelliToast.warning(
        i18next.t(`logicErrors.canNotInactivateDeviceTaInUse`)
      );
      seted = true;
      break;
    case "S403":
      IntelliToast.warning(
        i18next.t(`logicErrors.newStructureTypeNotHaveDeviceAC`)
      );
      seted = true;
      break;
    case "S404":
      IntelliToast.warning(
        i18next.t(`logicErrors.newStructureTypeNotHaveDeviceTA`)
      );
      seted = true;
      break;
    case "S405":
      IntelliToast.warning(i18next.t(`logicErrors.companyRequiredEntityTa`));
      seted = true;
      break;
    case "S406":
      IntelliToast.warning(i18next.t(`logicErrors.locationRequiredEntityTa`));
      seted = true;
      break;
    case "S407":
      IntelliToast.warning(i18next.t(`logicErrors.positionRequiredEntityTa`));
      seted = true;
      break;
    case "K461":
      IntelliToast.warning(
        i18next.t(`logicErrors.invalidDateLocationHaveScheduling`)
      );
      seted = true;
      break;
    case "K462":
      IntelliToast.warning(i18next.t(`logicErrors.totalCannotBeZero`));
      seted = true;
      break;
    case "K467":
      IntelliToast.warning(i18next.t(`logicErrors.scheduleNoAvalible`));
      seted = true;
      break;
    case "K468":
      IntelliToast.warning(i18next.t(`logicErrors.cannotDeleteResourceOld`));
      seted = true;
      break;
    case "K469":
      IntelliToast.warning(i18next.t(`logicErrors.cannotDeleteResourceUsed`));
      seted = true;
      break;
    case "K470":
      IntelliToast.warning(i18next.t(`logicErrors.invalidDateInt`));
      seted = true;
      break;
    case "K471":
      IntelliToast.warning(i18next.t(`logicErrors.locationNotFoud`));
      seted = true;
      break;
    case "K472":
      IntelliToast.warning(i18next.t(`logicErrors.isNotLocation`));
      seted = true;
      break;
    case "K473":
      IntelliToast.warning(i18next.t(`logicErrors.invalidScheduleForLocation`));
      seted = true;
      break;
    case "K474":
      IntelliToast.warning(i18next.t(`logicErrors.personHasScheduling`));
      seted = true;
      break;
    case "K475":
      IntelliToast.warning(i18next.t(`logicErrors.cannotUpdateResourceUsed`));
      seted = true;
      break;
    case "K476":
      IntelliToast.warning(i18next.t(`logicErrors.cannotUpdateResourceOld`));
      seted = true;
      break;
    case "K477":
      IntelliToast.warning(i18next.t(`logicErrors.schedulingNotFound`));
      seted = true;
      break;
    case "K478":
      IntelliToast.warning(i18next.t(`logicErrors.schedulingNotAvailables`));
      seted = true;
      break;
    case "435":
      IntelliToast.warning(i18next.t(`logicErrors.otherVistActive`));
      seted = true;
      break;
    case "T403":
      IntelliToast.warning(i18next.t(`logicErrors.timelogWithInvalidIP`));
      seted = true;
      break;
    case "EV400":
      IntelliToast.warning(
        i18next.t(`logicErrors.vacationPeriodNotAvailableForThisDate`)
      );
      seted = true;
      break;
    case "EV401":
      IntelliToast.warning(i18next.t(`logicErrors.vacationIsNotActive`));
      seted = true;
      break;
    case "EV402":
      IntelliToast.warning(i18next.t(`logicErrors.maxVacationLimitExceeded`));
      seted = true;
      break;
    case "EV403":
      IntelliToast.warning(
        i18next.t(`logicErrors.entityDoesNotHaveVacationPeriod`)
      );
      seted = true;
      break;
    case "EV405":
      IntelliToast.warning(
        i18next.t(`logicErrors.vacationDaysCanNotBeLessThanTheAmountEnjoyed`)
      );
      seted = true;
      break;
    case "EV406":
      IntelliToast.warning(
        i18next.t(`logicErrors.vacationEnjoymentDaysExceeded`)
      );
      seted = true;
      break;
    case "EV407":
      IntelliToast.warning(i18next.t(`logicErrors.noVacationPeriodDate`));
      seted = true;
      break;
    case "480":
      IntelliToast.warning(i18next.t("timeAttendance.errors.ENTITY_NOT_FOUND"));
      seted = true;
      break;
    case "481":
      IntelliToast.warning(
        i18next.t("timeAttendance.errors.ENTITY_DONT_HAVE_CONTRACT")
      );
      seted = true;
      break;
    case "482":
      IntelliToast.warning(
        i18next.t("timeAttendance.errors.INVALID_DATE_FOR_PERIOD")
      );
      seted = true;
      break;
    case "483":
      IntelliToast.warning(i18next.t("timeAttendance.errors.INVALID_DATE_IN"));
      seted = true;
      break;
    case "484":
      IntelliToast.warning(
        //i18next.t(`General.titleSuccess`),
        i18next.t("timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_PERMISSION")
      );
      seted = true;
      break;
    case "485":
      IntelliToast.warning(
        //i18next.t(`General.titleSuccess`),
        i18next.t("timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_SCHEDULE")
      );
      seted = true;
      break;
    default:
      seted = false;
      break;
  }
  return seted;
}

function renderSubError(key, value, model = "") {
  let text = "";
  switch (key) {
    case "if114":
      text = `(Max: ${value})`;
      break;
    case "if115":
      text = `(Min: ${value})`;
      break;
    case "if136":
      text = `${i18next.t(`${model}.${value}`)}`;
      break;
    case "if137":
      text = `${i18next.t(`${model}.${value}`)}`;
      break;
    default:
      text = "";
      break;
  }
  return text;
}

function errorHandlingAttributeModel(messages, model) {
  messages.map((error) => {
    Object.keys(error).forEach(function (code) {
      const value = error[code].split("-");
      const subError =
        value[1] !== undefined ? renderSubError(value[0], value[1], model) : "";
      IntelliToast.warning(
        `${i18next.t(`${model}.${code}`)} : ${i18next.t(
          `FormErrors.${value[0]}`
        )} ${subError}`
      );
    });
  });
}

function errorHandlingCodes(dispatch, error, model) {
  if (!error.response.data.status) {
    IntelliToast.warning(i18next.t("ServerError.500"));
    return;
  }

  const codError = error.response.data.status.toString();
  switch (error.response.data.status.toString()) {
    case "452":
      IntelliToast.warning(i18next.t("PermissionError.452"));
      break;
    case "454":
      errorHandlingAttributeModel(
        Object.values(error.response.data.message)[0],
        model
      );
      break;
    case "432":
      IntelliToast.warning(i18next.t("PermissionError.432"));
      break;
    case "433":
      IntelliToast.warning(i18next.t("PermissionError.433"));
      break;
    case "475":
      IntelliToast.warning(i18next.t("timeAttendance.errors.PERSON_HAS_USER"));
      break;
    case "476":
      IntelliToast.warning(i18next.t("timeAttendance.errors.USER_HAS_PERSON"));
      break;
    case "480":
      IntelliToast.warning(i18next.t("timeAttendance.errors.ENTITY_NOT_FOUND"));
      break;
    case "481":
      IntelliToast.warning(
        i18next.t("timeAttendance.errors.ENTITY_DONT_HAVE_CONTRACT")
      );
      break;
    case "482":
      IntelliToast.warning(
        i18next.t("timeAttendance.errors.INVALID_DATE_FOR_PERIOD")
      );
      break;
    case "483":
      IntelliToast.warning(i18next.t("timeAttendance.errors.INVALID_DATE_IN"));
      break;
    case "484":
      IntelliToast.warning(
        //i18next.t(`General.titleSuccess`),
        i18next.t("timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_PERMISSION")
      );
      break;
    case "485":
      IntelliToast.warning(
        //i18next.t(`General.titleSuccess`),
        i18next.t("timeAttendance.errors.INVALID_DATE_ENTITY_HAVE_SCHEDULE")
      );
      break;
    case "er500":
      IntelliToast.error(codError, i18next.t("ServerError.RedisError"));
      break;
    case "491":
      IntelliToast.warning(codError, i18next.t("ServerError.491"));
      break;
    case "494":
      IntelliToast.warning(codError, i18next.t("General.inUseParking"));
      break;
    case "rp506" /** Error restaurando contraseña  */:
      IntelliToast.warning(codError, i18next.t("Login.passwordResetedFailed"));
      break;
    case "va507" /** Error de validacion de campo */:
      IntelliToast.warning(codError, i18next.t("Login.validationError"));
      break;
    case "203":
      IntelliToast.warning(codError, i18next.t("PermissionError.203"));
      break;
    case "cl404":
      IntelliToast.error(codError, i18next.t("Login.clientError"));
      break;
    case "400":
      if (setError400(error.response.data.message)) {
        break;
      }
      IntelliToast.error("400", "400");
      break;
    case "404":
      if (model === "Device") {
        IntelliToast.error("404", i18next.t("Device.homologateNotFound"));
      }
      break;
    case "em500":
      IntelliToast.warning(
        i18next.t("ServerError.500", { code: error.response.data.code })
      );
      break;
    case "401":
      IntelliToast.warning(i18next.t("Login.userError"));
      break;
    case "et472":
      IntelliToast.warning(i18next.t("Login.disabledUser"));
      break;
    case "et473":
      IntelliToast.warning(i18next.t("Login.userBlocked"));
      break;
    case "et401":
      dispatch({
        type: LOGOUT,
      });
      IntelliToast.warning(i18next.t("Token.expired"));
      break;
    case "ec460":
      IntelliToast.warning(i18next.t("User.recoveryError"));
      break;
    case "et402":
      IntelliToast.warning(i18next.t("Token.invalid"));
      break;
    case "et403":
      IntelliToast.warning(i18next.t("Token.dismissed"));
      break;
    case "409":
      IntelliToast.warning(codError, i18next.t("ServerError.409"));
      break;
    case "440":
      IntelliToast.warning(codError, i18next.t("ServerError.440"));
      break;
    case "441":
      IntelliToast.warning(codError, i18next.t("ServerError.441"));
      break;
    case "403":
      IntelliToast.warning(codError, i18next.t("PermissionError.403"));
      break;
    case "410":
      IntelliToast.warning(codError, i18next.t("Login.invalidKey"));
      break;
    case "498":
      // dispatch({
      //   type: 'LICENSE_EXCEEDED',
      //   code: _.split(Object.values(error.response.data.message), '_')[1],
      // });
      break;
    case "499":
      // dispatch({
      //   type: 'LICENSE_NOT_FOUND',
      //   code: _.split(Object.values(error.response.data.message), '_')[1],
      // });
      break;
    case "500":
      const payload = {
        type: error.response.data.error ? 3 : 2,
        module: window.location.pathname,
        message: error.response.data.code ? `${error.response.data.code} - ${error.response.data.message}` : error.response.data.message,
        stack: error.response.request.responseURL,
        info: error.response.data.error ? error.response.data.error : `Line: ${error.response.data.line} - File: ${error.response.data.file}`
      }
      dispatch(errorBoundaryInsert(payload));
      IntelliToast.warning(
        codError,
        i18next.t("ServerError.500", { code: error.response.data.code })
      );
      break;
    case "110":
      errorHandlingAttributeModel(error.response.data.message, model);
      break;
    case "490":
      IntelliToast.error(i18next.t(`Login.invalidPassword`));
      break;
    case "493":
      IntelliToast.error(i18next.t(`General.notDeletedEntity`));
      break;
    case "741":
      IntelliToast.error(i18next.t(`FormErrors.entity_inactive`));
      break;
    case "745":
      IntelliToast.error(i18next.t(`FormErrors.moreThanOne`));
      break;
    case "748":
      IntelliToast.error(i18next.t(`FormErrors.notBelongto`));
      break;
    case "749":
      IntelliToast.error(i18next.t(`FormErrors.getCloser`));
      break;
    case "747":
      IntelliToast.error(i18next.t(`FormErrors.photoExists`));
      break;
    case "746":
      IntelliToast.error(i18next.t(`FormErrors.noFace`));
      break;
    case "471":
      IntelliToast.warning(
        i18next.t(
          "LocationScheduling.errors.INVALID_DATE_LOCATION_HAVE_SCHEDULING"
        )
      );
      break;
    case "473":
      IntelliToast.warning(
        i18next.t("LocationScheduling.errors.CANNOT_BE_UPDATE_THIS_RESOURCE")
      );
      break;
    case "745":
      IntelliToast.warning(i18next.t("Login.PERSON_ONLY_REMOTE"));
      break;
    case "742":
      IntelliToast.warning(i18next.t("login.email_for_administrator"));
      break;
    default:
      IntelliToast.error(`updateHandling ${status}`);
  }
}

export function errorHandling(dispatch, error, model) {
  if (error.response) {
    console.log({ error });
    errorHandlingCodes(dispatch, error, model);
  } else if (error.request) {
    console.info(error);
    if (error.request.status === 0) {
      IntelliToast.error(i18next.t("Login.serverDown"));
    } else {
      IntelliToast.error("The message");
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error 33', error.message);
    return false;
  }
  return true;
}

export function updateSuccess() {
  IntelliToast.success(i18next.t("General.UpdateSuccess"));
}
export function insertSuccess() {
  IntelliToast.success(i18next.t("General.InsertSuccess"));
}

export const jsonToQueryString = (params) => Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");

export const getQueryStringParams = (query) => {
  return query ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              const [key, value] = param.split("=");
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              return params;
            }, {}) : {};
};

export const hasComputerPermission = (SelectedEntity, type) => {
  let hasPermission = false;
  if (SelectedEntity !== null) {
    const activeEntity = SelectedEntity.computer;
    if (!activeEntity) return hasPermission;
    hasPermission = !!activeEntity[type];
  }
  return hasPermission;
};

export const getMedia = async (constraints) => {
  let stream = null;

  try {
    stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
  } catch (err) {
    throw i18next.t(`Errors.${err.name}`);
  }
};

export const actionRehydrate = (state, payload, key) => {
  if (payload) {
    if (payload[key]) {
      if (payload[key].data) {
        if (payload[key].data.results) {
          return payload[key];
        }
      }
    }
  }
  return state;
};

export const getBadgesStatusApproval = (data) => {
  if (!data.hasOwnProperty("status_approval")) return null;
  let text = "";
  let color = "";

  switch (parseInt(data.status_approval)) {
    case 1:
      text = i18next.t("Permissions.level1");
      color = "#14b598";
      break;
    case 2:
      text = i18next.t("Permissions.level2");
      color = "#f4786c";
      break;
    case 3:
      text = i18next.t("Permissions.level3");
      color = "#2e8bc7";
      break;
    case 4:
      text = i18next.t("Permissions.level4");
      color = "#fbb745";
      break;
    case 5:
      text = i18next.t("Permissions.level5");
      color = "#bebebe";
      break;
  }
  return <Badge style={{ background: color }}>{text}</Badge>;
};

export const getTypePermissions = (type) => {
  let text = "";

  if (type === 1) {
    text = i18next.t("Permissions.type_parcial");
  }
  if (type === 2) {
    text = i18next.t("Permissions.type_daily");
  }

  return text;
};

export const FileToBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    return cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const downloadFile = (data) => {
  if (typeof data === "object") {
    FileToBase64(data, (result) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = result;
      downloadLink.download = data.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  } else {
    const file = `${config.urlBase}/file/${data}`;
    window.open(file);
  }
};

export const formattedCalculation = (format_calculation, time_quantity) => {
  if (format_calculation === 1) {
    //fractions
    if (isNaN(time_quantity)) return `0 hrs 0 min`;

    const t = hoursDecimalToFractions(time_quantity);
    return `${t.hours} hrs ${t.minutes} min`;
  }
  if (format_calculation === 3) {
    if (isNaN(time_quantity)) return `00:00 hrs`;

    const t = hoursDecimalToFractions(time_quantity);
    const min = t.minutes < 10 && t.minutes >= 0 ? `0${t.minutes}` : t.minutes;
    return t.hours < 10 && t.hours >= 0 ? `0${t.hours}:${min} hrs` : `${t.hours}:${min} hrs`;
  }
  if (isNaN(time_quantity)) return `0 hrs`;
  return `${time_quantity.toFixed(2)} hrs`; //decimal
};

export const getFullFormatDateTime = (date, settings_user) => {
  if (!settings_user || !date) return null;
  const dateAux = formatDateTime(null, date, "date", settings_user);
  const time = formatDateTime(null, date, "time", settings_user);
  return `${dateAux} ${time}`;
};

export const calculationCantHours = (DateIn, DateOut) => {
  const start = moment(DateIn);
  const end = moment(DateOut);
  return moment.duration(end.diff(start)).hours();
};

export const setApexGraphicData = (
  obj,
  key,
  previewLabel = null,
  translateLabel = null,
  others = false
) => {
  let data = [];
  if (
    previewLabel === null &&
    obj !== undefined &&
    Object.values(obj.data.results).length > 0 &&
    obj.data.results[key] !== undefined
  ) {
    data.series = obj.data.results[key].map((i) => {
      return Math.abs(i.data);
    });
    data.labels = obj.data.results[key].map((i) => {
      const label = i.key === "0" && (translateLabel === null || others) ? i18next.t("Graphics.others") : translateLabel ? i18next.t(`${translateLabel}.${i.key}`) : i.key;
      return capitalize(label);
    });
    data.colors = obj.data.results[key].map((i, index) => {
      return colorsApexCharts[index];
    });
  } else if (previewLabel !== null) {
    const label = capitalize(i18next.t(previewLabel));
    data = {
      series: [25, 50, 15, 100, 75],
      labels: [
        `${label} A`,
        `${label} B`,
        `${label} C`,
        `${label} D`,
        `${label} E`,
      ],
      colors: ["#ffc94c", "#f48684", "#93cf96", "#45b6f3", "#775dd0"],
    };
  }
  return data;
};

export const truncNumber = (number, decimal = 0) => {
  if (Number.isInteger(number)) return number;
  return Math.floor(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

export const sortArrayForDates = (array, attr, asc = true) => {
  const arr = array.filter((item) => item[attr] !== null);
  return arr.sort((a, b) => {
    if (a[attr] <= b[attr]) {
      return asc ? -1 : 1;
    } else {
      return asc ? 1 : -1;
    }
  });
};

export const getUrlPhoto = (url) => {
  if (!url) return "";
  return url.indexOf("http") >= 0 ? url : `${config.urlBase}/file/${url}`;
};

export const getIconTypeTimeLog = (type) => {
  return (
    <IntelliTooltip
      title={getTypeAccess().find((ta) => ta.value === type).label || ""}
    >
      <div
        className={`avatar avatar-stats m-0 bg-light-${
          type === 1 ? "success" : "danger"
        }`}
        style={{ position: "initial" }}
      >
        <div className="avatar-content">
          <FontAwesomeIcon
            icon={type === 1 ? faRightToBracket : faRightFromBracket}
            style={{ height: "1.2em" }}
          />
        </div>
      </div>
    </IntelliTooltip>
  );
};

export const setDetailsVerificationMode = (
  verification_mode,
  id_verification_mode
) => {
  let types = _.split(verification_mode, "_")
    .map((item) => {
      let text = "";
      switch (item) {
        case "FINGERPRINT":
          text = ` ${i18next.t(
            "Timelog.Type_of_verification_modes.FINGERPRINT"
          )}`;
          break;
        case "CARD":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.CARD")}`;
          break;
        case "PIN":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.PIN")}`;
          break;
        case "FACIAL":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.FACIAL")}`;
          break;
        case "EYES":
        case "IRIS":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.IRIS")}`;
          break;
        case "PLATE":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.PLATE")}`;
          break;
        case "USER":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.USER")}`;
          break;
        case "PC":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.PC")}`;
          break;
        case "MOBILE":
          text = ` ${i18next.t("Timelog.Type_of_verification_modes.MOBILE")}`;
          break;
        default:
          break;
      }
      return text.toLowerCase();
    })
    .toString();
  types = [9, 10, 11, 12, 13, 14].includes(id_verification_mode) ? _.replace(types, ",", i18next.t("General.since")) : types;
  return types;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const getStatusofTimelogs = (type) => {
  let text = "";
  let color = "";

  switch (type.toLowerCase()) {
    case "entry":
      text = i18next.t("General.Entry");
      color = "#28c76f";
      break;
    case "exit":
      text = i18next.t("General.Exit");
      color = "#ea5455";
      break;
    case "delay":
      text = i18next.t("Timelog.Delay");
      color = "#ffa726";
      break;
    case "untimely-exit":
      text = i18next.t("Timelog.UntimelyExit");
      color = "#a353c6";
      break;
    case "in-break":
      text = i18next.t("Timelog.InBreak");
      color = "#2e8bc7";
      break;
    case "delay-with-permit":
      text = "delay-with-permit";
      color = "#eeadad";
      break;
    case "close-forced":
      text = "Cierre forzoso";
      color = "#000";
      break;
    case "correct":
      text = i18next.t("General.Correct");
      color = "#bebebe";
      break;
    default:
      text = "N/A";
      color = "#bebebe";
      break;
  }
  return <Badge style={{ background: color }}>{text}</Badge>;
};

export const getCurrentMapLocation = () => {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej, {
      enableHighAccuracy: true,
    });
  })
    .then((position) => ({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }))
    .catch(() => {});
};

export const hasPlanAndPlaces = (id_structure, structuresTree) => {
  if (!id_structure || !structuresTree || !structuresTree.length) return false;
  const structurePath = structuresTree.find(
    (s) => s.id_structure === id_structure
  ).path;

  return (
    structuresTree.filter(
      (s) => _.has(s.settings_structure_type, "sub_type") && 
            ["PT", "PR"].includes(s.settings_structure_type.sub_type) && s.path.includes(structurePath)
      ).length > 0
  );
};
