/* GLOBAL TYPES*/

export const PROCESSING = "PROCESSING";
export const PROCESS_COMPLETE = "PROCESS_COMPLETE";

export const POLAR_GET_DATA = 'POLAR_GET_DATA';
export const STACKED_GET_DATA = 'STACKED_GET_DATA';

export const SESSION_SET_INITAL_WORK = 'SESSION_SET_INITAL_WORK';
export const SESSION_SET_LAST = 'SESSION_SET_LAST';
export const PING_PING = 'PING_PING';
export const SESSION_START_MONITORING = 'SESSION_START_MONITORING';
export const SET_CAMERA_DEVICE = 'SET_CAMERA_DEVICE';
export const SET_ENROLL = 'SET_ENROLL';

export const LOGIN = 'LOGIN';
export const SELECT_ENTITY = 'SELECT_ENTITY';
export const SET_TEMPLATE_FACE = 'SET_TEMPLATE_FACE';
export const LOGOUT = 'LOGOUT';
export const MODIFY_USER_SETTING = "MODIFY_USER_SETTING";
export const PASSWORD_SEND_EMAIL = "PASSWORD_SEND_EMAIL";

export const ONLINE = "ONLINE"; 
export const OFFLINE = "OFFLINE";

/*
 * ACTIONS FORM
 */
export const EDIT_FORM = "EDIT_FORM";
export const ACTIONS_FORM_ADD = "ACTIONS_FORM_ADD";
export const ACTIONS_FORM_REMOVE = "ACTIONS_FORM_REMOVE";
export const ACTION_ADD_CHILD = "ACTION_ADD_CHILD";
export const ACTION_DELETE = "ACTION_DELETE";
export const ACTION_EDIT = "ACTION_EDIT";


/*
 * VERIFICATION_MODES
 */
export const VERIFICATION_MODES_FETCH = "VERIFICATION_MODES_FETCH";
export const VERIFICATION_MODES_FETCH_MORE = "VERIFICATION_MODES_FETCH_MORE";
export const VERIFICATION_MODES_FETCH_PENDING = "VERIFICATION_MODES_FETCH_PENDING";
export const VERIFICATION_MODES_REJECTED = "VERIFICATION_MODES_REJECTED";
export const VERIFICATION_MODES_UPDATE = "VERIFICATION_MODES_UPDATE";
export const VERIFICATION_MODES_INSERT = "VERIFICATION_MODES_INSERT";
export const VERIFICATION_MODES_DELETE = "VERIFICATION_MODES_DELETE";

/*
 * ENTITIES PERMISSION
 */
export const ENTITIES_PERMISSION_FETCH = "ENTITIES_PERMISSION_FETCH";
export const ENTITIES_PERMISSION_FETCH_PENDING = "ENTITIES_PERMISSION_FETCH_PENDING";
export const ENTITIES_PERMISSION_REJECTED = "ENTITIES_PERMISSION_REJECTED";

/*
* ENTITIES TIMES
*/

export const ENTITIES_TIMES_FETCH = "ENTITIES_TIMES_FETCH";
export const ENTITIES_TIMES_FETCH_PENDING = "ENTITIES_TIMES_FETCH_PENDING";

/*
* TIMELOGS
*/

export const TIMELOGS_FETCH = "TIMELOGS_FETCH";
export const TIMELOGS_FETCH_PENDING = "TIMELOGS_FETCH_PENDING";
export const TIMELOGS_REJECTED = "TIMELOGS_REJECTED";

/*
 * TIME ZONE
 */
export const TIME_ZONE_FETCH = "TIME_ZONE_FETCH";
export const TIME_ZONE_REJECTED = "TIME_ZONE_REJECTED";
export const TIME_ZONE_FETCH_MORE = "TIME_ZONE_FETCH_MORE";
export const TIME_ZONE_FETCH_PENDING = "TIME_ZONE_FETCH_PENDING";

/* STRUCTURES */

export const TREE_FETCH_PENDING = "TREE_FETCH_PENDING";
export const TREE_FETCH = "TREE_FETCH";
export const COWORKING_TREE_FETCH = "COWORKING_TREE_FETCH";
export const STRUCTURE_TYPES_FETCH = "STRUCTURE_TYPES_FETCH";
export const STRUCTURE_TYPES_FETCH_MORE = "STRUCTURE_TYPES_FETCH_MORE";
export const STRUCTURE_TYPES_FETCH_PENDING = "STRUCTURE_TYPES_FETCH_PENDING";
export const STRUCTURE_TYPES_REJECTED = "STRUCTURE_TYPES_REJECTED";

/* SCHEDULES */

export const SCHEDULES_FETCH_PENDING = "SCHEDULES_FETCH_PENDING";
export const SCHEDULE_REJECTED = "SCHEDULE_REJECTED";
export const SCHEDULES_FETCH = "SCHEDULES_FETCH";
export const SCHEDULES_FETCH_MORE = "SCHEDULES_FETCH_MORE";

//actual time by timezone
export const REAL_TIME_FETCH = "REAL_TIME_FETCH";
export const REAL_TIME_DIFERENCE = "REAL_TIME_DIFERENCE";