import { REHYDRATE } from "redux-persist/es/constants";
import { actionRehydrate } from "@utils";
import {
  ONLINE,
  OFFLINE
} from '../../constants';

const initialState = {
  online: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return actionRehydrate(state, action.payload, "status");
    case ONLINE:
      return {
        ...state,
        online: true
      };
    case OFFLINE:
      return {
        ...state,
        online: false
      };
    default:
      return state;
  }
}