import { REAL_TIME_FETCH} from "../../constants";

const initialState = {
  data: null,
};

const realTimeTimezoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case REAL_TIME_FETCH:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default realTimeTimezoneReducer;
