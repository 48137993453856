import { toast } from 'react-toastify'

export default {
  success: (msg) => {
    toast.success(msg, { toastId: msg })
  },
  info: (msg) => {
    toast.info(msg, { toastId: msg })
  },
  warning: (msg) => {
    toast.warning(msg, { toastId: msg })
  },
  error: (msg) => {
    toast.error(msg, { toastId: msg })
  }
}
