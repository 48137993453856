import { REAL_TIME_DIFERENCE } from "../../constants";

const initialState = {
  time: 0,
};

const realTimeDiference = (state = initialState, action) => {
  switch (action.type) {
    case REAL_TIME_DIFERENCE:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default realTimeDiference;
