import i18n from 'i18next';
import esCO from './translations/esCO.json';
import enUS from './translations/enUS.json';
import enDO from './translations/esDO.json';
import enVE from './translations/esVE.json';

const resources = {
  'es-CO': {
    translation: esCO,
  },
  'en-US': {
    translation: enUS,
  },
  'es-DO': {
    translation: enDO,
  },
  'es-VE': {
    translation: enVE,
  },
};

const userLang = navigator.language || navigator.userLanguage;
const lang = userLang.split("-")[0] === "es" ? "es-CO" : "en-US";

i18n.init({
  resources,
  debug: true,
  lng: localStorage.intl || lang,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
